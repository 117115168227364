<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col md="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_config.fiscal_year')"
                            label-for="email"
                        >
                            <v-select name="croptypeID"
                                v-model="search.fiscal_name"
                                label="text"
                                :options= fiscalList
                            />
                        </b-form-group>
                    </b-col>
                        <b-col  md="5">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('cotton_ginner_grower.hat_name')"
                                label-for="email"
                            >
                                <v-select name="croptypeID"
                                    v-model="search.hatt_name"
                                    label="text"
                                    :options= hatList
                                />
                            </b-form-group>
                        </b-col>
                    <b-col >
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('cotton_ginner_grower.sell-list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(hatt_date)="data">
                                            {{ data.item.hatt_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(quantity)="data">
                                            {{ $n(data.item.quantity, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(price)="data">
                                            {{ $n(data.item.price, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="this.$t('globalTrans.view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { sellEntryList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                fiscal_year_id: 0,
                hatt_id: 0
            }
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.orgList.filter(item => item.status === 0)
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.sort((a, b) => b.sorting_order - a.sorting_order)
        },
        productionSeasonList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
        },
        hatList: function () {
             return this.$store.state.agriMarketing.commonObj.hatList.filter(item => item.status === 1)
         },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('cotton_ginner_grower.sell') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsSeeds.dealer_wise_allocation') + ' ' + this.$t('globalTrans.update')
        },
        dealerList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('warehouse_config.fiscal_year'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.season'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.hat_name'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.cottonVariety'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.cotton'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.unit'), class: 'text-center' },
                { label: this.$t('globalTrans.date'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.quantity'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.price'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                        { key: 'index' },
                        { key: 'fiscal_year_bn' },
                        { key: 'season_name_bn' },
                        { key: 'hatt_name_bn' },
                        { key: 'cotton_variety_name_bn' },
                        { key: 'cotton_name_bn' },
                        { key: 'unit_name_bn' },
                        { key: 'hatt_date' },
                        { key: 'quantity' },
                        { key: 'price' }
                ]
            } else {
                keys = [
                   { key: 'index' },
                    { key: 'fiscal_year' },
                    { key: 'season_name' },
                    { key: 'hatt_name' },
                    { key: 'cotton_variety_name' },
                    { key: 'cotton_name' },
                    { key: 'unit_name' },
                    { key: 'hatt_date' },
                    { key: 'quantity' },
                    { key: 'price' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        async loadData () {
            this.$store.dispatch('setList', [])
            if (this.search.fiscal_name) {
                this.search.fiscal_year_id = this.search.fiscal_name.value
            }
            if (this.search.hatt_name) {
                this.search.hatt_id = this.search.hatt_name.value
            }
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            await RestApi.getData(agriMarketingServiceBaseUrl, sellEntryList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },    
        getRelationalData (data) {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            const hattNameList = this.$store.state.agriMarketing.commonObj.hatList
            const seasonNameList = this.$store.state.agriMarketing.commonObj.seasonList
            const cottonVarietyList = this.$store.state.agriMarketing.commonObj.cottonVaritiesList
            const cottonNameList = this.$store.state.agriMarketing.commonObj.cottonNameList
            const unitNameList = this.$store.state.agriMarketing.commonObj.unitList
            return data.data.map(item => {
                const fiscalyearObject = fiscalyearData.find(fiscalyear => fiscalyear.value === item.fiscal_year_id && fiscalyear.status === 0)
                const hattNameObject = hattNameList.find(hattName => hattName.value === item.hatt_id && hattName.status === 1)
                const seasonNameObject = seasonNameList.find(season => season.value === item.seasons_id && season.status === 1)
                const cottonVarietyObject = cottonVarietyList.find(cottonVariety => cottonVariety.value === item.cotton_variety_id && cottonVariety.status === 1)
                const cottonNameObject = cottonNameList.find(cottonName => cottonName.value === item.cotton_id && cottonName.status === 1)
                const unitNameObject = unitNameList.find(unitName => unitName.value === item.unit_id && unitName.status === 1)
                
                const FiscalYearData = {
                    fiscal_year: fiscalyearObject !== undefined ? fiscalyearObject.text_en : '',
                    fiscal_year_bn: fiscalyearObject !== undefined ? fiscalyearObject.text_bn : ''
                }

                 const hattNameData = {
                    hatt_name: hattNameObject !== undefined ? hattNameObject.text_en : '',
                    hatt_name_bn: hattNameObject !== undefined ? hattNameObject.text_bn : ''
                }

                const seasonData = {
                    season_name: seasonNameObject !== undefined ? seasonNameObject.text_en : '',
                    season_name_bn: seasonNameObject !== undefined ? seasonNameObject.text_bn : ''
                }

                const cottonVarietyData = {
                    cotton_variety_name: cottonVarietyObject !== undefined ? cottonVarietyObject.text_en : '',
                    cotton_variety_name_bn: cottonVarietyObject !== undefined ? cottonVarietyObject.text_bn : ''
                }

                const cottonData = {
                    cotton_name: cottonNameObject !== undefined ? cottonNameObject.text_en : '',
                    cotton_name_bn: cottonNameObject !== undefined ? cottonNameObject.text_bn : ''
                }

                 const unitNameData = {
                    unit_name: unitNameObject !== undefined ? unitNameObject.text_en : '',
                    unit_name_bn: unitNameObject !== undefined ? unitNameObject.text_bn : ''
                }
                
                return Object.assign({}, item, FiscalYearData, hattNameData, seasonData, cottonVarietyData, cottonData, unitNameData)
            })
        }
    }
}
</script>
